import React, {useEffect, useState, useRef} from "react";

//Export context
export const BlogContext = React.createContext();

const BlogProvider = (props) => {
  
  const {data, pageNumbers, currentPage} = props;

  const firstPage = currentPage === 1;
  const lastPage = currentPage === pageNumbers;
  const back = currentPage - 1 === 1 ?  `/blog` : `/blog/page/${currentPage - 1}`;
  const next = `/blog/page/${currentPage + 1}`;
  
  //Props to send through context for sharing between components
  const blogProps = {data, firstPage, lastPage, back, next};

  return (
    <BlogContext.Provider value={blogProps}>
      {props.children}
    </BlogContext.Provider>
  );
}

export default BlogProvider;